.cash {
    border-radius: 0.5rem;
    padding: 0.35em 0.65em;
    color:#ffffff;
    width: 20%;
    display: inline-block;
    text-align: center;
    margin: 2px;
    background: #2EB85C;
    font-weight: bold;
    font-size:25px;
    line-height: 1;
    cursor: pointer;
}

.selected-row{
  background-color: '#a7e1eb';
}

.credit {
  border-radius: 0.5rem;
  padding: 0.35em 0.65em;
  color:#ffffff;
  width: 20%;
  display: inline-block;
  text-align: center;
  margin: 2px;
  background: #b6a231;
  font-weight: bold;
  font-size:25px;
  line-height: 1;
  cursor: pointer;
}


.cat {
    border-radius: 0.5rem;
    padding: 0.35em 0.65em;
    color:#ffffff;
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 2px;
    margin-top: 10px;
    background: #171c23;
    font-size:20px;
    line-height: 1.2;
    cursor: pointer;
}


.subCat {
  border-radius: 0.5rem;
  padding: 0.35em 0.65em;
  color:#ffffff;
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 2px;
  background: #2476b0;
  font-size:18px;
  font-weight: 500;
  line-height: 2;
  cursor: pointer;
}

.searchItem {
    width: 25%;
    float: left;
    margin-right: 10px;
}

.dropdown {
  width: 30%;
  float: right;
}

.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #ccc;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #ccc;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  