.card {
    width:99%;
    margin: auto;
    padding:1%;
    
}


.tittle {
    border-radius: 0.25rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #1f2937;
    color: white;
}


.active-link {
    border: 4px solid #ff9800;
  }

.bx {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}


.header-total {
    border-radius: 0.25rem;
    padding: 8px;
    color:#ffffff;
    width: auto;
    text-align: center;
    margin: 2px;
    background: #30b255;
    font-weight: bold;
    font-size:15px;
    line-height: 1;
    float: right;
}


.header-usd {
    border-radius: 0.25rem;
    padding: 8px;
    color:#ffffff;
    width: auto;
    text-align: center;
    margin: 2px;
    background: #1f2937;
    font-weight: bold;
    font-size:15px;
    line-height: 0.8;
    float: right;
}


/* Swtich */


.switch {
    position: relative;
    display: inline-block;
    width: 50px !important;
    height: 25px !important;
  }
  

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px !important;
    width: 22px !important;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  
  .switch input:checked + .slider {
    background-color: #4CAF50; 
  }
  
  .switch input:checked + .slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
  }
  

/* Swtich End */

.dropDown{

    width: 10% !important;
    float: left;
    margin-right: 1%;
}

.dropDownStockActivity{

    width: 15% !important;
}

.dropDownMedium{
    width: 16% !important;
    float: left;
    margin-right: 1%;
    margin-bottom: 1%;
}

.dropDownLarge{
    width: 100% !important;
    float: left;
    margin-right: 1%;
    margin-bottom: 1%;
}

.formLabel{
 font-weight: bold;
}

.dropDownCustomer{

    width: 25% !important;
    float: left;
    margin-right: 1%;
}


.dropDownCategory{

    width: 20% !important;
    float: left;
    margin-right: 1%;
}

.dropDownPurchase{

    width: 50% !important;
}

.dropDownSettings{

    width: 70%;
    float: left;
    margin-right: 1%;
}


.dropCustomer{

    width: 25%;
    float: right;
}

.search{
    width: 15% !important;
    float: left;
    margin-right: 1%;
    margin-bottom: 1%;
}


.date{
    width: 12% !important;
    float: left;
    margin-right: 1%;
}



.btn-add {
    border-radius: 0.25rem ;
    padding: 0.35em 0.65em;
    color:#ffffff!important;
    width: 10% !important;
    text-align: center;
    margin: 2px !important  ;
    background: #3066b2 !important;
    font-weight: bold !important;
    font-size:15px !important;
    line-height: 1 !important;
    float: right;
}

.custom-modal-width .modal-dialog {
    max-width: 88%;
    max-height: 90vh;
  }

  
.dropDown-btn {
    float: right;
    margin-right: 70px;
}


.btn-end {
    border-radius: 0.25rem ;
    padding: 0.35em 0.65em;
    color:#ffffff!important;
    width: 10% !important;
    text-align: center;
    margin: 2px !important  ;
    background: #e63946 !important;
    font-weight: bold !important;
    font-size:15px !important;
    line-height: 1 !important;
    float: right;
}




.tab-one {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: #2b7b76;
    color: #ffffff;
    margin: 0.5%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : 13%;
} 

.tab-two {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: #39aaa5;
    color: #ffffff;
    margin: 0.5%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : 13%;
}

.tab-three {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: #658b6f;
    color: #ffffff;
    margin: 0.5%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : 13%;
} 


.tab-red {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: #e03e4e;
    color: #ffffff;
    margin: 0.5%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : 13%;
}

.navDropdown {
    color: white;
    width: auto;
  }

.tab-five {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: #1d7790;
    color: #ffffff !important;
    margin: 0.5%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : 13%;
}

.tab-four {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: #6d9197;
    color: #ffffff;
    margin: 0.5%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : auto;
}

.tab-six {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: #2b9d8f;
    color: #ffffff;
    margin: 0.5%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : 13%;
}

.tab-seven {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: #2e565d;
    color: #ffffff;
    margin: 0.5%;
    margin-bottom: 1%;
    margin-top: 1%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : 13%;
}


.table-footer {
    border-radius: 0.3rem !important;
    border: solid 1px;
    padding: 4px;
    font-weight: 600;
    font-size: medium;

}

.footer-one {
    color: #ffffff;
    border-color: #2b7b76;
    background-color: #2b7b76;
    margin: 5px;
}

.footer-red {
    color: #ffffff;
    border-color: #e6434f;
    background-color: #e6434f;
    margin: 5px;
}

.footer-green {
    color: #ffffff;
    border-color: #388e3c;
    background-color: #388e3c;
    margin: 5px;
}

.footer-yellow {
    color: #ffffff;
    border-color: #f0b103;
    background-color: #f0b103;
    margin: 5px;
}

.custom-dropdown-button .btn::after,
.custom-dropdown-button .dropdown-toggle::after {
  display: none !important;
}


.mini-dropdown-button .btn::after,
.mini-dropdown-button .dropdown-toggle::after {
  display: none !important;
}

.mini-dropdown-button {
    width: 4px;
    margin-top: -3px !important;
    margin-right: 10%;
}

.nav-text {
    padding: 10px;
    margin: 25px;
    line-height: 2.5;
    color: white;
    
} 

.tab-text {
    padding: 10px;
    line-height: 1;
    
} 




.tab-total {
    border-radius: 0.25rem;
    padding: 0.35em 0.65em;
    width: auto;
    display: inline-block;
    text-align: center;
    margin: 2px;
    margin-top: 20px;
    font-weight: bold;
    line-height: 1;
    color: green;
    border-color: green;
    border-width: 2px;
    border-style: double;
}



.header-total {
    border-radius: 0.25rem;
    padding: 0.40em 0.65em;
    color:#ffffff;
    width: auto;
    text-align: center;
    margin: 2px;
    background: #30b255;
    font-weight: bold;
    font-size:15px;
    line-height: 1;
    float: right;
    cursor: default;
}

.tab-rate {
    border-radius: 0.25rem;
    padding: 0.35em 0.65em;
    width: auto;
    display: inline-block;
    text-align: center;
    margin: 2px;
    margin-top: 20px;
    font-weight: bold;
    line-height: 1;
    border-color: black;
    border-width: 2px;
    border-style: double;
}

.tab-toggle {
    flex: 1;
    display: flex;
    align-items: center;
    float: left;
    background: rgb(107, 67, 179);
    color: #ffffff;
    margin: 0.5%;
    padding: 1%;
    border-radius: 1rem;
    height: 45px;
    width : auto;
    font-weight:500;
}



/*Type cell in table */
.type-green {
    background: #2eb85c;
    border-radius: 0.25rem;
    padding: 0.35em 0.65em;
    color:white;
    width:  100%;
    display: inline-block;
    line-height: 1;
    text-align: center;
}

.type-red {
    background: #ff4364;
    border-radius: 0.25rem;
    padding: 0.35em 0.65em;
    color:white;
    width:  100%;
    display: inline-block;
    line-height: 1;
    text-align: center;
}

.type-yellow {
    background: rgb(240, 177, 3);
    border-radius: 0.25rem;
    padding: 0.35em 0.65em;
    color:white;
    width: 100%;
    display: inline-block;
    line-height: 1;
    text-align: center;
}

/* view total */
.total_money {
    background: #1f2937;
    border-radius: 0.25rem;
    padding: 0.35em 0.65em;
    color:#ffffff;
    width: 80%;
    display: inline-block;
    line-height: 1;
    text-align: center;
    margin: 2px;
}


.btn-red {

    background: #e03e4e;
    color: white;
}

.btn-green {

    background: #2EB85C;
    color: white;
}




  /* HTML: <div class="loader"></div> */
.loginloader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 8px solid;
    border-color: #000 #0000;
    animation: l1 1s infinite;
    margin-left: 50%;
    margin-top: 38vh;
    position: absolute;
  }
  @keyframes l1 {to{transform: rotate(.5turn)}}