

.ant_table > div > div > div,
.ant_table > div > div > div > div {
  height: 72vh;
}

.ant_table > div   {
  min-height: 70vh;
  height: 76vh;
}

.ant_table .ant-table-tbody {
  min-height: 70vh; /* Set a minimum height for the table body */
}

.ant_table_mini > div,
.ant_table_mini > div > div,
.ant_table_mini > div > div > div,
.ant_table_mini > div > div > div > div {
  height: 66vh;
}

.ant_table_mini > div > div > div > div {
  min-height: 57vh;
}


  

.odd-row{
    background-color: #fcfcfc !important;
}

.isDone-row{
  background-color: #f6ffed !important;
}

.isNotDone-row{
  background-color: #ffdedc !important;
}


.header-bg {
  background-color: #1e8ebe; /* Replace with your desired background color */
  color: white; /* Optional: Change text color for better contrast */
  font-size: 15px;
  line-height: 1;
}

